import { defineStore } from 'pinia';
import { computed } from 'vue';

import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import {
  useSportlineSidebarFavoriteLeaguesComposable,
  useSportlineSidebarSettingsComposable,
  useSportlineSidebarSyncBackgroundRequestsComposable,
} from 'web/src/modules/sportline/submodules/sidebar/store/composables';
import { useSportsListStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useSidebarSportsUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';

import { filterSportsTreeCybersportElements } from '../utils';
import { useTwitterTimelineCybersportStoreComposable } from './composables';

export const useCybersportSidebarStore = defineStore('sportline-cybersport-sidebar', () => {
  const {
    maxTopLeaguesInTab: maxLeaguesInTab,
    maxTopLeaguesTabsInSidebar: maxTabsCount,
  } = useSportlineSidebarSettingsComposable();
  const { timeout: sidebarSportsUpdateTimeout } = useSidebarSportsUpdateTimeout();

  const twitterTimelineComposable = useTwitterTimelineCybersportStoreComposable();

  const {
    fullSportsList,
    reloadAllSportsLists,
  } = useSportsListStoreComposable({ updateInterval: sidebarSportsUpdateTimeout });

  const fullCybersportTree = computed(() => filterSportsTreeCybersportElements(fullSportsList.value));

  const {
    fullFavoriteLeagues,
  } = useSportlineSidebarFavoriteLeaguesComposable({ fullSportsList: fullCybersportTree });

  const {
    onInit: globalSportlineEventsOnInit,
  } = useGlobalSportlineEvents();
  const {
    onInit: backgroundRequestsOnInit,
    setBackgroundUpdateEnabled,
  } = useSportlineSidebarSyncBackgroundRequestsComposable({
    updateInterval: sidebarSportsUpdateTimeout,
    reloadList: reloadAllSportsLists,
  });

  globalSportlineEventsOnInit();
  backgroundRequestsOnInit();

  return {
    ...twitterTimelineComposable,
    fullCybersportTree,
    fullFavoriteLeagues,
    maxTabsCount,
    maxLeaguesInTab,
    setBackgroundUpdateEnabled,
  };
});
