import { defineStore } from 'pinia';
import { computed } from 'vue';

import { logger } from '@leon-hub/logging';
import { RouteName } from '@leon-hub/routing-config-names';

import type {
  RegionElement,
  SportElement,
} from 'web/src/modules/sportline/types';
import {
  SportSegmentId,
} from 'web/src/modules/sportline/enums';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { useSegmentStoreComposable } from 'web/src/modules/sportline/submodules/segment/store/composables';
import { checkMatchStreamIndicator, useMatchStreamAccess } from 'web/src/modules/sportline/submodules/streams';
import {
  useAllLiveCybersportPageUpdateInterval,
  useComingLiveCybersportPageUpdateInterval,
  useSelectedCybersportLivePageUpdateInterval,
} from 'web/src/modules/sportline/submodules/update-timeout';
import { filterRegionsListEvents } from 'web/src/modules/sportline/utils';

import { useCybersportAllRegionsStoreComposable } from './composables';

export const useSportlineCybersportStore = defineStore('sportline-cybersport-store', () => {
  const matchStreamAccess = useMatchStreamAccess();

  const {
    isPageEnabled,
    isReady: isSegmentReady,
    filterIsReady,
    isMarketTypesSelectionEnabled,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    selectedFilter,
    activeRegionElement,
    rawLiveEventsResponse,
    rawComingEventsResponse,
    rawFullSportsListResponse,
    fullSportsList,
    fullEventsResponse,
    sportSegmentsSettings,
    hasFavoritesOptimistic,
    isFavoritesTabAvailable,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    favoriteSelectedRegionFamily,
    comingResponseWithoutLive,
    liveDisciplinesMap,
    comingDisciplinesMap,
    compiledComingDisciplinesList,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    setSelectedFilter,
    initialRequests: initialSegmentRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled: setSegmentBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  } = useSegmentStoreComposable({
    sportSegmentsSettingsId: RouteName.SPORTLINE_CYBERSPORT,
    backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey.CYBERSPORT,
    filterFavoritesEventsListBySegmentId: SportSegmentId.CyberSport,
    allLiveTabUpdateInterval: useAllLiveCybersportPageUpdateInterval().timeout,
    selectedLiveTabUpdateInterval: useSelectedCybersportLivePageUpdateInterval().timeout,
    comingUpdateInterval: useComingLiveCybersportPageUpdateInterval().timeout,
  });

  const sportElement = computed<Maybe<SportElement>>(() => {
    const list = fullSportsList.value
      .filter((element) => element.sport.representation.family === sportSegmentsSettings.value.sportFamily)
      .map((sportListElement): SportElement => ({
        key: sportListElement.sport.id,
        sport: sportListElement.sport,
        regions: (sportListElement.regions ?? []).map((regionListElement) => ({
          key: regionListElement.region.id,
          region: regionListElement.region,
          marketTypes: [],
          leagues: [],
          counters: regionListElement.counters,
        })),
        marketTypes: [],
        marketsColumns: [],
        counters: sportListElement.counters,
      }));

    if (list.length > 1) {
      logger.warn('More then one Cybersport presented', list.map((element) => element.sport.id));
    }

    return list?.[0] || null;
  });
  const disciplinesList = computed<Maybe<readonly RegionElement[]>>(() => {
    const cybersport = sportElement.value;
    return cybersport
      ? Object.freeze(cybersport.regions
          .filter((regionElement) => (regionElement.counters.live > 0 || regionElement.counters.prematch > 0)))
      : null;
  });

  const customerStreamsDisciplinesList = computed<RegionElement[]>(() => (matchStreamAccess.value.length > 0
    ? filterRegionsListEvents(
        disciplinesList.value ?? [],
        (sportEventElement) => checkMatchStreamIndicator(matchStreamAccess.value, sportEventElement.sportEvent),
      )
    : []));

  const {
    isReady: isSportsTreeReady,
    sportsTreeElement,
    sportsTreeDateTimeFilter,
    isRegionOpen,
    setOpenRegionState,
    setSportsTreeDateTimeFilter,
    initialRequests: initialSportsTreeRequests,
    setBackgroundUpdateEnabled: setSportsTreeBackgroundUpdateEnabled,
    reloadSportsTreeForFilter,
  } = useCybersportAllRegionsStoreComposable({ sportElement });

  const isReady = computed(() => (isSportsTreeReady.value && isSegmentReady.value));

  const totalCount = computed(() => sportsTreeElement.value?.counters.total ?? 0);

  async function initialRequests(): Promise<void> {
    await Promise.allSettled([
      initialSegmentRequests(),
      initialSportsTreeRequests(),
    ]);
  }

  function setBackgroundUpdateEnabled(value: boolean): void {
    setSegmentBackgroundUpdateEnabled(value);
    void setSportsTreeBackgroundUpdateEnabled(value);
  }

  return {
    isPageEnabled,
    isReady,
    filterIsReady,
    totalCount,
    isMarketTypesSelectionEnabled,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    selectedFilter,
    activeRegionElement,
    rawLiveEventsResponse,
    rawComingEventsResponse,
    rawFullSportsListResponse,
    sportElement,
    disciplinesList,
    fullEventsResponse,
    hasFavoritesOptimistic,
    isFavoritesTabAvailable,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    favoriteSelectedRegionFamily,
    comingResponseWithoutLive,
    liveDisciplinesMap,
    comingDisciplinesMap,
    compiledComingDisciplinesList,
    customerStreamsDisciplinesList,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    sportsTreeElement,
    sportsTreeDateTimeFilter,
    isRegionOpen,
    setOpenRegionState,
    setSportsTreeDateTimeFilter,
    reloadSportsTreeForFilter,
    setSelectedFilter,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  };
});
